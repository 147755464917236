import { Injectable, Inject } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root',
})

export class DatadogService {
        constructor() {}
    
        init(): void {
        if (environment.enableDatadog) {
                console.log('Datadog initialized in production..!'); 
                datadogRum.init({
                applicationId: '26859b27-19ab-405d-8d6d-530590843df9',    
                clientToken: 'pubb7d09bbc3c3505daffc4c2d3debdf9a5',
                site: 'us3.datadoghq.com',    
                service: 'myprimerx',    
                env: 'prod',  
                sessionSampleRate: 100,    
                sessionReplaySampleRate: 20,    
                trackUserInteractions: true,   
                trackResources: true,    
                trackLongTasks: true,    
                defaultPrivacyLevel: 'mask-user-input'
            });
            datadogRum.startSessionReplayRecording();
        } else {
            console.log('Datadog is disabled for this environment');
        }
    }
}