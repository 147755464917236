import { ErrorHandler, Injectable, NgZone } from '@angular/core'; 
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class GlobalErrorsHandlerComponent implements ErrorHandler {
    constructor(private _ngzone: NgZone) {}

    handleError(error: any): void {
        this._ngzone.run(() => {
            if (error instanceof HttpErrorResponse) {
                //server errors
                this.logError(error.status.toString(), error.message);
            } else {
                //client errors
                this.logError(error.status.toString(), error.message);
            }
        });

        console.error('An error occurred is :', error);
    }
    private logError(state: string, message: string){
        const errorData = { state, message, timestamp: new Date().toISOString() };
        const existingLogs = JSON.parse(localStorage.getItem('errorLogs') || '[]');
        existingLogs.push(errorData);
        localStorage.setItem('errorLogs', JSON.stringify(existingLogs));
    }
}