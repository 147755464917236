import { noop } from '@datadog/browser-core';
export function createPreStartStrategy() {
  var status = 0 /* PreStartRecorderStatus.None */;
  return {
    strategy: {
      start: function () {
        status = 1 /* PreStartRecorderStatus.HadManualStart */;
      },

      stop: function () {
        status = 2 /* PreStartRecorderStatus.HadManualStop */;
      },

      isRecording: function () {
        return false;
      },
      getSessionReplayLink: noop
    },
    shouldStartImmediately: function (configuration) {
      return status === 1 /* PreStartRecorderStatus.HadManualStart */ || status === 0 /* PreStartRecorderStatus.None */ && !configuration.startSessionReplayRecordingManually;
    }
  };
}
