import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { WebPubSubService } from './services/WebPubSubService';
import { environment } from '../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit{
  title = 'Patient Engagement'; 
  metaData:any;
  patientID_npi:any;
  isSecurePatientComm!:boolean;
  constructor(
    private webPubSubService: WebPubSubService,
    private cd: ChangeDetectorRef)
    {}
  ngOnInit(){
    this.isSecurePatientComm = environment.isSecurePatientComm;
    this.metaData = JSON.parse(localStorage.getItem('MetaData') as string);  
      if(this.metaData && this.isSecurePatientComm){
        this.patientID_npi = JSON.stringify(this.metaData?.patientId) +'_'+ this.metaData?.pharmacyNpi;
        this.webPubSubService.initializePatientWebSocket(this.patientID_npi);
    }
  }
}
